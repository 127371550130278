export default {
  journals: [
        { value: 'ijire', label: 'International Journal of Innovative Research in Engineering (IJIRE)', scode: 'IJIRE',id: 1},
        { value: 'ijrtmr', label: 'International Journal of Recent Trends in Multidisciplinary Research (IJRTMR)', scode: 'IJRTMR',id: 2},
        { value: 'ijsreat', label: 'International Journal of Scientific Research in Engineering & Technology (IJSREAT)', scode: 'IJSREAT',id: 3},
        { value: 'indjece', label: 'Indian Journal of Electronics and Communication Engineering (INDJECE)', scode: 'INDJECE',id: 4},
        { value: 'indjeee', label: 'Indian Journal of Electrical and Electronics Engineering (INDJEEE)', scode: 'INDJEEE',id: 5},
        { value: 'indjcst', label: 'Indian Journal of Computer Science and Technology (INDJCST)', scode: 'INDJCST',id: 6}
  ]
}
