import VueRouter from 'vue-router';
import routes from './routes';

import auth from '../util/auth';

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  if(!auth.userLoggedIn() && to.name !== 'Author')
  {
    if(to.name === 'Login' || to.name === 'Recovery') {
      next()
    }
    else{
      next({
        path: '/authorlogin',
        query: { redirect: to.fullPath }
      })
    }
  } else if(auth.userLoggedIn()) {
    if(to.name === 'Author' || to.name === 'Recovery' || to.name === 'Login') {
      next({
        path: '/dashboard',
      })
    } else{
      next()
    }
  } else {
    next()
  }
})

export default router;
