import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';

import Login from 'src/pages/Pages/Login.vue';
import Register from 'src/pages/Pages/AuthorLogin.vue';

const Charts = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Charts.vue');

const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');
import Widgets from 'src/pages/Widgets.vue';

const ValidationForms = () => import('src/pages/Forms/ValidationForms.vue');

const RegularTables = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Tables/RegularTables.vue');

const ExtendedTables = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Tables/ExtendedTables.vue');

const Enquiry = () =>
  import ('src/pages/Enquiry/Enquiry.vue');
const AddEnquiry = () =>
  import ('src/pages/Enquiry/AddEnquiry.vue');

const Submission = () =>
  import ('src/pages/Submission/Submission.vue')

const AddSubmission = () =>
  import ('src/pages/Submission/AddSubmission.vue')

const EditSubmission = () =>
  import ('src/pages/Submission/EditSubmission.vue')

const Archives = () =>
  import ('src/pages/Archives/Archives.vue')

const EditArchive = () =>
  import ('src/pages/Archives/EditArchives.vue')

const AddArchives = () =>
  import ('src/pages/Archives/AddArchives.vue')

const Authors = () =>
  import ('src/pages/Users/Authors.vue');
const AddAuthors = () =>
  import ('src/pages/Users/AddAuthors.vue');

const Indexing = () =>
  import ('src/pages/Indexing/Indexing.vue');

const AddIndexing = () =>
  import ('src/pages/Indexing/AddIndexing.vue');

const Editorial = () =>
import ('src/pages/Editorial/Editorial.vue');
const AddEditorial = () =>
import ('src/pages/Editorial/AddEditorial.vue')

let ijire = {
  path: '/ijire',
  component: DashboardLayout,
  name: 'IJIRE',
  redirect: '/ijire/dashboard',
  children: [
    {
      path: 'dashboard',
      name: 'IJIRE Dashboard',
      components: { default: Dashboard }
    },
    {
      path: 'authors',
      name: 'IJIRE Authors',
      components: { default: Authors }
    },
    {
      path: 'addauthors',
      name: 'IJIRE AddAuthors',
      components: { default: AddAuthors }
    },
    {
      path: 'reviewers',
      name: 'IJIRE Reviewers',
      components: { default: ValidationForms }
    },
    {
      path: 'editors',
      name: 'IJIRE Editors',
      components: { default: RegularTables }
    },
    {
      path: 'submissions',
      name: 'IJIRE Submissions',
      components: { default: Submission }
    },
    {
      path: 'indexing',
      name: 'IJIRE Indexing',
      components: { default: Indexing }
    },
    {
      path: 'addindexing',
      name: 'IJIRE Add Indexing',
      components: { default: AddIndexing }
    },
    {
      path: 'Addsubmissions',
      name: 'IJIRE Addsubmissions',
      components: { default: AddSubmission }
    },
    {
      path: 'editsubmission',
      name: 'IJIRE Editsubmission',
      components: { default: EditSubmission }
    },
    {
      path: 'reports',
      name: 'IJIRE Reports',
      components: { default: Charts }
    },
    {
      path: 'enquiry',
      name: 'IJIRE Enquiry',
      components: { default: Enquiry }
    },
    {
      path: 'add-enquiry',
      name: 'IJIRE AddEnquiry',
      components: { default: AddEnquiry }
    },
    {
      path: 'editorial',
      name: 'IJIRE Editorial',
      components: { default: Editorial }
    },
    {
      path: 'add-editorial',
      name: 'IJIRE AddEditorial',
      components: { default: AddEditorial }
    },
    {
      path: 'Archives',
      name: 'IJIRE Archives',
      components: { default: Archives }
    },
    {
      path: 'AddArchives',
      name: 'IJIRE AddArchives',
      components: { default: AddArchives }
    },
    {
      path: 'EditArchive',
      name: 'IJIRE EditArchives',
      components: { default: EditArchive }
    },

  ]
}

let ijrtmr = {
  path: '/ijrtmr',
  component: DashboardLayout,
  name: 'IJRTMR',
  redirect: '/ijrtmr/dashboard',
  children: [
    {
      path: 'dashboard',
      name: 'IJRTMR Dashboard',
      components: { default: Dashboard }
    },
    {
      path: 'authors',
      name: 'IJRTMR Authors',
      components: { default: Authors }
    },
    {
      path: 'addauthors',
      name: 'IJRTMR AddAuthors',
      components: { default: AddAuthors }
    },
    {
      path: 'reviewers',
      name: 'IJRTMR Reviewers',
      components: { default: ValidationForms }
    },
    {
      path: 'editors',
      name: 'IJRTMR Editors',
      components: { default: RegularTables }
    },
    {
      path: 'submissions',
      name: 'IJRTMR Submissions',
      components: { default: Submission }
    },
    {
      path: 'indexing',
      name: 'IJRTMR Indexing',
      components: { default: Indexing }
    },
    {
      path: 'addindexing',
      name: 'IJRTMR Add Indexing',
      components: { default: AddIndexing }
    },
    {
      path: 'Addsubmissions',
      name: 'IJRTMR Addsubmissions',
      components: { default: AddSubmission }
    },
    {
      path: 'reports',
      name: 'IJRTMR Reports',
      components: { default: Charts }
    },
    {
      path: 'enquiry',
      name: 'IJRTMR Enquiry',
      components: { default: Enquiry }
    },
    {
      path: 'add-enquiry',
      name: 'IJRTMR AddEnquiry',
      components: { default: AddEnquiry }
    },
    {
      path: 'editorial',
      name: 'IJRTMR Editorial',
      components: { default: Editorial }
    },
    {
      path: 'add-editorial',
      name: 'IJRTMR AddEditorial',
      components: { default: AddEditorial }
    },
    {
      path: 'Archives',
      name: 'IJRTMR Archives',
      components: { default: Archives }
    },
    {
      path: 'AddArchives',
      name: 'IJRTMR AddArchives',
      components: { default: AddArchives }
    },
    {
      path: 'EditArchive',
      name: 'IJRTMR EditArchives',
      components: { default: EditArchive }
    },

  ]
}

let ijsreat = {
  path: '/ijsreat',
  component: DashboardLayout,
  name: 'IJSREAT',
  redirect: '/ijsreat/dashboard',
  children: [
    {
      path: 'dashboard',
      name: 'IJSREAT Dashboard',
      components: { default: Dashboard }
    },
    {
      path: 'authors',
      name: 'IJSREAT Authors',
      components: { default: Authors }
    },
    {
      path: 'addauthors',
      name: 'IJSREAT AddAuthors',
      components: { default: AddAuthors }
    },
    {
      path: 'reviewers',
      name: 'IJSREAT Reviewers',
      components: { default: ValidationForms }
    },
    {
      path: 'editors',
      name: 'IJSREAT Editors',
      components: { default: RegularTables }
    },
    {
      path: 'submissions',
      name: 'IJSREAT Submissions',
      components: { default: Submission }
    },
    {
      path: 'indexing',
      name: 'IJSREAT Indexing',
      components: { default: Indexing }
    },
    {
      path: 'addindexing',
      name: 'IJSREAT Add Indexing',
      components: { default: AddIndexing }
    },
    {
      path: 'Addsubmissions',
      name: 'IJSREAT Addsubmissions',
      components: { default: AddSubmission }
    },
    {
      path: 'reports',
      name: 'IJSREAT Reports',
      components: { default: Charts }
    },
    {
      path: 'enquiry',
      name: 'IJSREAT Enquiry',
      components: { default: Enquiry }
    },
    {
      path: 'add-enquiry',
      name: 'IJSREAT AddEnquiry',
      components: { default: AddEnquiry }
    },
    {
      path: 'editorial',
      name: 'IJSREAT Editorial',
      components: { default: Editorial }
    },
    {
      path: 'add-editorial',
      name: 'IJSREAT AddEditorial',
      components: { default: AddEditorial }
    },
    {
      path: 'Archives',
      name: 'IJSREAT Archives',
      components: { default: Archives }
    },
    {
      path: 'AddArchives',
      name: 'IJSREAT AddArchives',
      components: { default: AddArchives }
    },
    {
      path: 'EditArchive',
      name: 'IJSREAT EditArchives',
      components: { default: EditArchive }
    },
  ]
}

let indjeee = {
  path: '/indjeee',
  component: DashboardLayout,
  name: 'INDJEEE',
  redirect: '/indjeee/dashboard',
  children: [
    {
      path: 'dashboard',
      name: 'INDJEEE Dashboard',
      components: { default: Dashboard }
    },
    {
      path: 'authors',
      name: 'INDJEEE Authors',
      components: { default: Authors }
    },
    {
      path: 'addauthors',
      name: 'INDJEEE AddAuthors',
      components: { default: AddAuthors }
    },
    {
      path: 'reviewers',
      name: 'INDJEEE Reviewers',
      components: { default: ValidationForms }
    },
    {
      path: 'editors',
      name: 'INDJEEE Editors',
      components: { default: RegularTables }
    },
    {
      path: 'submissions',
      name: 'INDJEEE Submissions',
      components: { default: Submission }
    },
    {
      path: 'addindexing',
      name: 'INDJEEE Add Indexing',
      components: { default: AddIndexing }
    },
    {
      path: 'indexing',
      name: 'INDJEEE Indexing',
      components: { default: Indexing }
    },
    {
      path: 'Addsubmissions',
      name: 'INDJEEE Addsubmissions',
      components: { default: AddSubmission }
    },
    {
      path: 'reports',
      name: 'INDJEEE Reports',
      components: { default: Charts }
    },
    {
      path: 'enquiry',
      name: 'INDJEEE Enquiry',
      components: { default: Enquiry }
    },
    {
      path: 'add-enquiry',
      name: 'INDJEEE AddEnquiry',
      components: { default: AddEnquiry }
    },
    {
      path: 'editorial',
      name: 'INDJEEE Editorial',
      components: { default: Editorial }
    },
    {
      path: 'add-editorial',
      name: 'INDJEEE AddEditorial',
      components: { default: AddEditorial }
    },
    {
      path: 'Archives',
      name: 'INDJEEE Archives',
      components: { default: Archives }
    },
    {
      path: 'AddArchives',
      name: 'INDJEEE AddArchives',
      components: { default: AddArchives }
    },
    {
      path: 'EditArchive',
      name: 'INDJEEE EditArchives',
      components: { default: EditArchive }
    },
  ]
}

let indjece = {
  path: '/indjece',
  component: DashboardLayout,
  name: 'INDJECE',
  redirect: '/indjece/dashboard',
  children: [
    {
      path: 'dashboard',
      name: 'INDJECE Dashboard',
      components: { default: Dashboard }
    },
    {
      path: 'authors',
      name: 'INDJECE Authors',
      components: { default: Authors }
    },
    {
      path: 'addauthors',
      name: 'INDJECE AddAuthors',
      components: { default: AddAuthors }
    },
    {
      path: 'reviewers',
      name: 'INDJECE Reviewers',
      components: { default: ValidationForms }
    },
    {
      path: 'editors',
      name: 'INDJECE Editors',
      components: { default: RegularTables }
    },
    {
      path: 'submissions',
      name: 'INDJECE Submissions',
      components: { default: Submission }
    },
    {
      path: 'indexing',
      name: 'INDJECE Indexing',
      components: { default: Indexing }
    },
    {
      path: 'addindexing',
      name: 'INDJECE Add Indexing',
      components: { default: AddIndexing }
    },
    {
      path: 'Addsubmissions',
      name: 'INDJECE Addsubmissions',
      components: { default: AddSubmission }
    },
    {
      path: 'reports',
      name: 'INDJECE Reports',
      components: { default: Charts }
    },
    {
      path: 'enquiry',
      name: 'INDJECE Enquiry',
      components: { default: Enquiry }
    },
    {
      path: 'add-enquiry',
      name: 'INDJECE AddEnquiry',
      components: { default: AddEnquiry }
    },
    {
      path: 'editorial',
      name: 'INDJECE Editorial',
      components: { default: Editorial }
    },
    {
      path: 'add-editorial',
      name: 'INDJECE AddEditorial',
      components: { default: AddEditorial }
    },
    {
      path: 'Archives',
      name: 'INDJECE Archives',
      components: { default: Archives }
    },
    {
      path: 'AddArchives',
      name: 'INDJECE AddArchives',
      components: { default: AddArchives }
    },
    {
      path: 'EditArchive',
      name: 'INDJECE EditArchives',
      components: { default: EditArchive }
    },
  ]
}

let indjcst = {
  path: '/indjcst',
  component: DashboardLayout,
  name: 'INDJCST',
  redirect: '/indjcst/dashboard',
  children: [
    {
      path: 'dashboard',
      name: 'INDJCST Dashboard',
      components: { default: Dashboard }
    },
    {
      path: 'authors',
      name: 'INDJCST Authors',
      components: { default: Authors }
    },
    {
      path: 'addauthors',
      name: 'INDJCST AddAuthors',
      components: { default: AddAuthors }
    },
    {
      path: 'reviewers',
      name: 'INDJCST Reviewers',
      components: { default: ValidationForms }
    },
    {
      path: 'editors',
      name: 'INDJCST Editors',
      components: { default: RegularTables }
    },
    {
      path: 'submissions',
      name: 'INDJCST Submissions',
      components: { default: Submission }
    },
    {
      path: 'indexing',
      name: 'INDJCST Indexing',
      components: { default: Indexing }
    },
    {
      path: 'addindexing',
      name: 'INDJCST Add Indexing',
      components: { default: AddIndexing }
    },
    {
      path: 'Addsubmissions',
      name: 'INDJCST Addsubmissions',
      components: { default: AddSubmission }
    },
    {
      path: 'reports',
      name: 'INDJCST Reports',
      components: { default: Charts }
    },
    {
      path: 'enquiry',
      name: 'INDJCST Enquiry',
      components: { default: Enquiry }
    },
    {
      path: 'add-enquiry',
      name: 'INDJCST AddEnquiry',
      components: { default: AddEnquiry }
    },
    {
      path: 'editorial',
      name: 'INDJCST Editorial',
      components: { default: Editorial }
    },
    {
      path: 'add-editorial',
      name: 'INDJCST AddEditorial',
      components: { default: AddEditorial }
    },
    {
      path: 'Archives',
      name: 'INDJCST Archives',
      components: { default: Archives }
    },
    {
      path: 'AddArchives',
      name: 'INDJCST AddArchives',
      components: { default: AddArchives }
    },
    {
      path: 'EditArchive',
      name: 'INDJCST EditArchives',
      components: { default: EditArchive }
    },
  ]
}

const routes = [
  {
    path: '/',
    redirect: '/ijire',
    name: 'Home'
  },

  ijire,
  ijrtmr,
  ijsreat,
  indjece,
  indjeee,
  indjcst,
  {
    path: '/auth',
    name: 'Auth',
    component: AuthLayout,
    children: [{
      path: '/editorlogin',
      name: 'Login',
      component: Login
    },
    {
      path: '/authorlogin',
      name: 'Author',
      component: Register
    }]
  },

  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard }
      },
      {
        path: 'widgets',
        name: 'Widgets',
        components: { default: Widgets }
      }
    ]
  },
];

export default routes;
