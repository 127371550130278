<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <!-- <sidebar-fixed-toggle-button /> -->
    <side-bar
      @journalChange="changeActiveJournal"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: $t('sidebar.dashboard'),
            icon: 'tim-icons icon-components',
            path: 'dashboard'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.editorialBoard'),
            icon: 'tim-icons icon-badge',
            path: 'editorial'
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.indexing'),
            icon: 'tim-icons icon-bullet-list-67',
            path: 'indexing'
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.archives'),
            icon: 'tim-icons icon-notes',
            path: 'archives'
          }"
        ></sidebar-item>
      </template>
    </side-bar>
  
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar :activeJournal="activeJournal"></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */

import 'perfect-scrollbar/css/perfect-scrollbar.css';
import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import { ZoomCenterTransition } from 'vue2-transitions';
import journals from '../../util/journals';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    ZoomCenterTransition,
  },
  data() {
    return {
      sidebarBackground: 'blue', //vue|blue|orange|green|red|primary
      activeJournal: 'ijire',
      journalList: journals.journals
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    changeActiveJournal(val) {
      console.log(this.$route)
      this.activeJournal = val
      let activeJournalId = this.getJournalId()
      store.commit('setJournal', val)
      store.commit('setJournalId', activeJournalId)
      this.$router.push({name: val.toUpperCase()+' Dashboard'})
    },
    getJournalId() {
      let value = this.journalList.filter((val, index) => val.value === this.activeJournal)
      return value[0].id
    }
  },
  mounted: function() {
    if(!this.$route.path.includes(store.state.journal)) {
      this.changeActiveJournal(this.activeJournal)
    }
  }
};
</script>



<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
