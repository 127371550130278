/* globals Store */
import api from './backend-api'
import Store from './store'

export default {
  userLogin (email, pass, authData, cb) {
    cb = arguments[arguments.length - 1]
    authData.email = email
    authData.password = pass
    // let data = 'username=' + email + '&password=' + pass
    api.login('login', authData).then((res) => {
      let status = res.loginstatus || res.data.loginstatus.status
      if (status === 'success') {
        // let token = res.access_token || res.data.access_token
        let token = res.token || res.data.loginstatus.sid
        // let user = res.user || res.data.loginstatus.userEmail
        let userid = res.userid || res.data.loginstatus.uid
        localStorage.usertoken = token
        localStorage.userid = userid
        // localStorage.useremail = user
        this.setUserData()
        if (cb) cb(true, null)
        this.onChange(true)
      } else {
        this.alert.showErrorAlert('Invalid User Credentials')
        if (cb) cb(false, null)
        this.onChange(false)
      }
    }, (err) => {
      this.alert.showErrorAlert(err)
      if (cb) cb(false, err)
      this.onChange(false)
    })
  },
  setUserData () {
    Store.commit('setUserToken', localStorage.usertoken)
    Store.commit('setUser', localStorage.useremail)
    Store.commit('setUserId', localStorage.userid)
  },
  getUserToken () {
    if (!Store.state.usertoken) {
      if (localStorage.usertoken) {
        Store.commit('setUserToken', localStorage.usertoken)
      }
    }
    return Store.state.usertoken
  },
  logout (cb) {
    delete localStorage.usertoken
    Store.commit('setUserToken', null)
    if (cb) cb(false)
    this.onChange(false)
  },
  userLoggedIn () {
    console.log(Store.state.usertoken)
    if (Store.state.usertoken) {
      if (localStorage.usertoken) {
        this.setUserData()
        Store.commit('setUserToken', localStorage.usertoken)
      }
    }

    return !!Store.state.usertoken
  },
  onChange () {}
}
