var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',{on:{"journalChange":_vm.changeActiveJournal}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.dashboard'),
          icon: 'tim-icons icon-components',
          path: 'dashboard'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.editorialBoard'),
          icon: 'tim-icons icon-badge',
          path: 'editorial'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.indexing'),
          icon: 'tim-icons icon-bullet-list-67',
          path: 'indexing'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.archives'),
          icon: 'tim-icons icon-notes',
          path: 'archives'
        }}})],1)],2),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar',{attrs:{"activeJournal":_vm.activeJournal}}),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }